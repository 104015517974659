import React from "react"
import createTemplate from "../../create-template"

import { useComponents } from "../../components"

function King({ styles, ...props }) {
  const Elements = useComponents()
  const {
    backgroundColor: bg_color,
    backgroundImage: bg_image,
    ...bg_styles
  } = styles.forElement("bg_image")
  const checkboxes_color = styles.forElement("form_heading").color
  const form = React.createRef()

  return (
    <Elements.LandingPage
      styles={styles}
      {...props}
      style={styles.forElement("page")}
    >
      <div className="formkit-container">
        <div
          className="formkit-bg-color"
          style={{ backgroundColor: bg_color }}
        />
        <div
          className="formkit-bg-image"
          style={{
            "--bg-color": bg_color,
            "--bg-color-0": bg_color + "00",
            "--bg-color-70": bg_color + "70",
            "--bg-image": bg_image,
            ...bg_styles
          }}
        />
        <div className="formkit-header">
          <Elements.Content
            className="formkit-preheader"
            name="preheader"
            defaults={{
              content: "<p>Permanent restaurant open now</p>"
            }}
          />
          <Elements.Heading
            className="formkit-heading"
            name="heading"
            defaults={{
              content: "Ramen by Russo"
            }}
          />
          <Elements.Region className="formkit-subheader" name="subheader">
            <Elements.Content
              defaults={{
                content:
                  "<p>The viral restaurant sensation is finally a permanent New York fixture and you're invited!</p>"
              }}
            />
          </Elements.Region>
          <Elements.Button
            className="formkit-signup"
            name="button_link"
            type="button"
            group="button_link"
            onClick={() => form.current.scrollIntoView({ behavior: "smooth" })}
            defaults={{
              content: "Get a free dessert with any full-priced main"
            }}
          />
        </div>
        <div className="formkit-max">
          <div
            className="formkit-content-main"
            style={styles.forElement("content_bg")}
          >
            <Elements.Heading
              className="formkit-content-heading"
              name="content_heading"
              tag="h2"
              defaults={{
                content:
                  "Experience the cult-classics that Ramen by Russo fans rave about!"
              }}
            />
            <Elements.Region className="formkit-content" name="content">
              <Elements.Content
                defaults={{
                  content:
                    "<p>&#8220;I've never had ramen like it! What Jordan has created is truly revolutionary&#8221;</p><p>&#8220;Ramen By Russo needs to be a permanent restaurant so that I can eat those dumplings every single day.&#8221;</p><p>&#8220;You do not want to miss out on the Ramen By Russo experience. Book your table now, and make sure you order the Katsu – it will change your life!&#8221;</p>"
                }}
              />
            </Elements.Region>
          </div>
          <div
            className="formkit-form-main"
            style={styles.forElement("form_bg")}
            ref={form}
          >
            <Elements.Heading
              className="formkit-form-heading"
              name="form_heading"
              tag="h2"
              defaults={{
                content: "Ready for the Ramen by Russo experience?"
              }}
            />
            <Elements.Region
              className="formkit-form-content"
              name="form_content"
            >
              <Elements.Content
                defaults={{
                  content:
                    "<p>We'd love to welcome you and treat you to a free dessert with any full-priced main.</p>"
                }}
              />
            </Elements.Region>
            <Elements.Form>
              <Elements.Errors />
              <Elements.CustomFields
                style={{
                  color: checkboxes_color
                }}
              >
                <Elements.AddFieldButton />
                <Elements.Button
                  name="submit"
                  group="button"
                  defaults={{ content: "Claim your free dessert!" }}
                />
              </Elements.CustomFields>
            </Elements.Form>
            <Elements.Content
              className="formkit-disclaimer"
              name="disclaimer"
              defaults={{
                content:
                  "<p>We respect your privacy. Unsubscribe at any time.</p>"
              }}
            />
          </div>
        </div>
        <Elements.BuiltWith background="bg_image" />
      </div>
    </Elements.LandingPage>
  )
}

export default createTemplate(King, { name: "King" })
